$(function(){
	//アコーディオン
	$('.accordion-btn').click(function(){
		$(this).toggleClass('active');
		$(this).prev('.accordion-body').slideToggle();
	});
	//スムーズスクロール
  $('a[href^="#"]').click(function(){
    var adjust = 0;
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top + adjust;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
  
  var btn = $('#fix-btn');
  //navの位置    
  var btnTop = btn.offset().top;
  var btnH = btn.outerHeight();
  var btnBottom = btnTop + btnH;
  console.log(btnBottom);

  //スクロールするたびに実行
  $(window).scroll(function () {
      var winBottom = $(window).height() + $(window).scrollTop();
      console.log(winBottom);
      //スクロール位置がnavの位置より下だったらクラスfixedを追加
      if (winBottom > btnBottom) {
        btn.addClass('onfix')
      } else if (winBottom <= btnBottom) {
        btn.removeClass('onfix')
      }
  });

  //背景変更
	$(window).on("load scroll resize", function(){
		var page_h = $(document).height();
		var block_h = page_h / 3;
		var position = $(window).scrollTop();
		var showClass = "show";
		if(position < block_h) {
			$('.bg-cover').not('.bg-img01').removeClass(showClass);
			$('.bg-img01').addClass(showClass);
		}
		else if(position < block_h * 2){
			$('.bg-cover').not('.bg-img02').removeClass(showClass);
			$('.bg-img02').addClass(showClass);
		}
		else if(position <= block_h * 3){
			$('.bg-cover').not('.bg-img03').removeClass(showClass);
			$('.bg-img03').addClass(showClass);
		}
  });

});
